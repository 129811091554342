import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"

import Helmet from "react-helmet"
import { Layout, PostCard, Pagination } from "../components/common"
import { MetaData } from "../components/common/meta"
import Footer from "components/Footer/Footer.jsx"
import GridContainer from "components/Grid/GridContainer.jsx"
import GridItem from "components/Grid/GridItem.jsx"
import PortableText from "components/common/PortableText.js"
import Card from "components/Card/Card.jsx"
import CardBody from "components/Card/CardBody.jsx"
import CardHeader from "components/Card/CardHeader.jsx"
import withStyles from "@material-ui/core/styles/withStyles"
import tagStyle from "assets/jss/material-kit-react/views/landingPageSections/tagStyle.jsx"

import "typeface-roboto"
import "typeface-roboto-slab"

import { urlFor } from "utils/image-url"

/**
 * Tag page (/tag/:slug)
 *
 * Loads all posts for the requested tag incl. pagination.
 *
 */
const Tag = ({ classes, data, location, pageContext }) => {
    const tag = data.sanityCategory
    const posts = data.allSanityPost.edges
    const mainImage = data.allSanityPost.edges[0].node.mainImage
    return (
        <>
            <Helmet>
                <link
                    rel="stylesheet"
                    href="https://use.typekit.net/php8vfy.css"
                />
            </Helmet>
            <MetaData data={data} location={location} image={mainImage} type="series" />
            <Layout isHome={false} mainImage={mainImage}>
                <div>
                    <GridContainer>
                        {posts.map(function (edge, i) { 
                            return (
                                <GridItem
                                    xs={12}
                                    sm={6}
                                    md={4}
                                    style={{ margin: `0 auto` }}
                                    key={i}
                                >
                                    <Card plain>
                                        <CardHeader>
                                            <Link to={`/` + edge.node.slug.current}>
                                                <h3 className={classes.title}>
                                                    {edge.node.title}
                                                </h3>
                                                <img
                                                    src={urlFor(edge.node.mainImage)
                                                        .width(800)
                                                        .height(600)
                                                        .url()}
                                                    alt={edge.node.mainImage.alt}
                                                    className={
                                                        classes.imgRaised +
                                                    ` ` +
                                                    classes.imgFluid
                                                    }
                                                />
                                            </Link>
                                        </CardHeader>
                                        <CardBody>
                                            <PortableText
                                                blocks={edge.node._rawExcerpt}
                                            />
                                        </CardBody>
                                    </Card>
                                </GridItem>
                            )
                        }
                        )
                        }
                    </GridContainer>
                </div>
            </Layout>
            <Footer/>
        </>
    )
}

Tag.propTypes = {
    data: PropTypes.shape({
        sanityCategory: PropTypes.object.isRequired,
        allSanityPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default withStyles(tagStyle)(Tag)

export const pageQuery = graphql`
    query SanityCategoryQuery($slug: String!) {
        sanityCategory(slug: { current: { eq: $slug } }) {
            title
            description
            slug {
                current
            }
        }
        allSanityPost(
            sort: { order: DESC, fields: [publishOrder] }
            filter: {
                categories: { elemMatch: { slug: { current: { eq: $slug } } } }
            }
        ) {
            edges {
                node {
                    ...SanityPostFields
                }
            }
        }
    }
`
